import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Crowdfunding Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/crowdfunding-web-app/"
    metaDescription="Say hello to our brand-new crowdfunding website design template. To get started, sign up for Uizard Pro and bring your idea to life."
    description="
    h2:Introducing our crowdfunding website design template
    <br/>
    Working on your own design for a brand-new crowdfunding website? Then look no further than our a <a:https://uizard.io/templates/website-templates/>website design template</a> for crowdfunding. Givingly comes with all the screens and user journeys your design could need, saving you countless hours of work. Simply sign up to Uizard Pro and select Givingly to fast-track your design flow.
    <br/>
    h3:Design your crowdfunding website collaboratively
    <br/>
    Teamworking has never been easier than it is with Uizard. Simply share your project link with your team or invite them via email for instant collaboration and feedback. Make slow <a:https://uizard.io/prototyping/>UX/UI prototyping</a> a thing of the past and get stakeholder signoff quicker than ever before.
    <br/>
    h3:Customize easily with our drag-and-drop editor
    <br/>
    Whether you are new to design or know your way around a design tool or two, you'll find Uizard super easy to use. Our templates come with everything you could need for your project, but adapting, customizing, and updating the template is quick and easy. Use our design components, update user journeys, and add new screens with the click of a button.
    "
    pages={[
      "A chic landing page in stunning lime green",
      "Crowdfunding campaign landing screen",
      "Campaign details and fundraising screen",
      "Donation screen with 'Pay now' CTA",
    ]}
    projectCode="8oJJGXYj4yCWAoe9Jj9A"
    img1={data.image1.childImageSharp}
    img1alt="crowdfunding web app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="crowdfunding web app design template campaign overview screen"
    img3={data.image3.childImageSharp}
    img3alt="crowdfunding web app design template campaign setup screen"
    img4={data.image4.childImageSharp}
    img4alt="crowdfunding web app design template campaign settings screen"
    img5={data.image5.childImageSharp}
    img5alt="crowdfunding web app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/crowdfunding-web-app/crowdfunding-app-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/crowdfunding-web-app/crowdfunding-app-web-project-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/crowdfunding-web-app/crowdfunding-app-web-personal-project.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/crowdfunding-web-app/crowdfunding-app-web-settings.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/crowdfunding-web-app/crowdfunding-app-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
